/*
 * @Author: heyang
 * @Description:  飞行模拟
 * @ModuleName:  飞行模拟
 * @Date: 2018-09-27 17:38:59
 * @Last Modified by: WYY
 * @Last Modified time: 2020-03-05 14:58:29
 */
<style lang="less" scoped>
    .Unit_con{
        .content_t{
            height: 100% !important;
            padding: 0.3rem 0.6rem;
            .VideoPlayer{
                height: 100%;
            }
        }
        .Fangda{
            bottom: 0px !important;
        }
    }
</style>
<style lang="less" scoped>
    #Center_Content_Video{
        width: 80%;
        height: auto;
        display: block;
        padding: 25px;
        margin: 0 auto;
    }

</style>
<template>
        <div class="T_box">
            <Head :Target='Target' :RightLeft='RightLeft' @show='pshow=true' :UpLayerData='UpLayerData' />
            <!-- 作答提示音 -->
            <!-- <video :id="`Hint`" class="video-js vjs-default-skin vjs-big-play-centered flex-grid">
            </video> -->
            <AudioPlayer ref="HintAudioPlayer"  :theUrl="HintAudioPlayerUrl" @audioEndedOver="HintPlayerComplete" :config="HintAudioPlayerConfig" />
            <div class="T_content">
                <div class="Unit_d">
                    <div class="Unit_con">
                    <!-- <div class="ShuiYinRight">中国民用航空西北地区空中交通管理局 </div> -->
                    <!-- <div class="ShuiYinLeft">{{Username}}</div> -->
                        <!-- 播放 -->
                        <div class="Second_title">
                            <div class="Audio_play">
                                <span class="t1" style="font-weight:bold;font-style:italic;"> No.{{questionIndex+1}}/{{questionAll}} </span>
                                <div></div>
                                <div class="describe" @click="pshow = !pshow"  v-if="Pattern!='测试模式'">
                                    <img :src=" require( '@/assets/shuoming.png')" alt="">
                                    <span>Directions</span>
                                </div>
                            </div>
                        </div>


                        <div class="Question_box clearfix">
                            <div :class="['question_des',(threeActive=='')?'active':'']">
                                <div class="content_t content_t_fly">
                                    <VideoPlayer ref="VideoPlayer"  :theUrl="videoSrc" @videoEndedOver='PlayerComplete'></VideoPlayer>
                                </div>
                            </div>

                            
                            <div class="Text Simu" v-show="threeActive=='TEXT'">
                                <div class="title">Text <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                <div style="height:100%;">
                                    <div class="t_content" v-html="this.questionText" style="border:none">
                                        <div class="T_con" id="Simu">
                                            <div >
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="Notes" v-show="threeActive=='NOTES'">
                                <div class="title">Notes  <i class="el-icon-close right" @click="threeShowClose"></i> </div>
                                <div class="t_content">
                                    <textarea name="" v-model="NoteItem.content" @focus="NoteFocus()" @blur="NoteBlur()" maxlength="500" id="" cols="30" rows="10"></textarea>
                                    <img v-if="NoteItem.content.length<=0" src="../../assets/notesbc.png" class="bc" alt="">
                                    <button class="save" @click="SaveNotes"> Save </button>
                                </div>
                                </div>
                                <!-- key -->
                                <div class="Text" v-show="threeActive=='KEY'">
                                    <div class="title">Key <i class="el-icon-close right" @click="threeShowClose"></i></div>
                                    <div class="t_content" v-html="this.questionData.answers"></div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="T_option">
                <div class="Unit_option">
                    <div class="btn_list">
                        <!-- back -->
                        <span class="btn" v-if="Pattern!='测试模式'" @click="On_back()"></span>
                        <!-- next -->
                        <span class="btn2" v-if="Pattern!='测试模式'" @click="On_next()"></span>

                        <div class="Record_btn" v-if="RecordeItem.show">
                            <img src="../../assets/Microphone.png" class="T_1" alt="" @click="Record_a('1')">
                                <img src="../../assets/pause.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && this.RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn">
                                <img src="../../assets/play_bai.png" class="T_2" alt="" @click='MediaPlay()' v-if="Pattern!='测试模式' && !RecordeItem.MediaInPlay && recorderPlayerUrl && !RecordBtn">
                            <div class="Re_conm"  v-if='RecordBtn'>
                                <span class="horn"></span>
                                <div class="Re_box">
                                    <div id="Wave">
                                        <div v-for="i in 10" class="WaveItem" :key="i" :style="'height:'+ Wheight(i) +'px'">
                                        </div>
                                    </div>

                                </div>
                                <div class="countdown"> {{RecordeItem.timeshow}} </div>
                            </div>
                        </div>
                        <Recorder ref="Recorder" @upRcData="AddAudio"></Recorder>
                        <AudioPlayer ref="recorderPlayer"  :theUrl="recorderPlayerUrl"   @audioEndedOver="recorderPlayerAudioEndedOver" :config="recorderPlayerConfig" />
                        <div class="btn_cover"  v-if='RecordBtn'></div>
                        </div>
                        <Time :Pattern='Pattern'/>
                    </div>
                </div>
            </div>


            <!-- <div class="Machine" v-if="Pattern!='测试模式'">
                <img v-if="mshow.text==0" src="../../assets/TEXT3.png" alt="" style="cursor:not-allowed;">
                <img v-if="mshow.text==1" src="../../assets/1TEXT.png" alt="" @click="Clicktext">
                <img v-if="mshow.text==2" src="../../assets/2TEXT.png" alt=""  @click="mshow.text=1">
                <img v-if="mshow.notes==0" src="../../assets/NOTES3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.notes==1" src="../../assets/1NOTES.png" alt=""  @click="Clicknotes">
                <img v-if="mshow.notes==2" src="../../assets/2NOTES.png" alt=""  @click="mshow.notes=1">
                <img v-if="mshow.key==0" src="../../assets/KEY3.png" alt=""  style="cursor:not-allowed;">
                <img v-if="mshow.key==1" src="../../assets/1KEY.png" alt="" @click="mshow.key=2;mshow.notes=1;mshow.text=1;">
                <img v-if="mshow.key==2" src="../../assets/2KEY.png" alt="" @click="mshow.key=1">
            </div> -->

            <popup :show.sync="pshow" :describe='this.Target.description'></popup>
            <div class="Re_cover" v-if='RecordBtn'></div>
        </div>



</template>
<script>

    import popup from '../describe/Popup.vue'
    import Head from '../../components/head.vue'
    export default {
        data(){
            return {
                mshow:{
                    text:1,
                    notes:1,
                    key:1,
                    // 0 不可选 1 可选 2 已选
                },
                Panduan:true,
                Target:{
                    catalog:'',
                    description:'',
                    isEnd:null,
                    title:'',
                },
                RightLeft:false,
                pshow:false,

                questionData:{},
                childQuestionData:{},
                questionText:"",
                questionAudio:"",
                questionImg:"",

                questionIndex:0,
                questionAll:0,
                childQuestionListIndex:0,
                childQuestionListAll:0,

                // 播放器 参数  命名 不要重复   mixin 中使用了 参数
                GetTime:false, // 获取 视频总时长
                audioTime:0, // 滑块 value
                player:null, // 播放器
                videoPlayer:null,//视频播放器
                playertime:'', //总时长
                InPlay:false, // 判断是否 播放中
                // 播放器 参数

                check:'',
                complate:false,
                answer:'',

                NoteItem:{
                    id:'',
                    content:'',
                },
                // 绑题 规则
                BindRole:{},
                // 树形 上层数据
                UpLayerData:{},

                // 录音
                RecordeItem:{
                    show:true,
                    time:null,
                    timeshow:'',
                    Count:null,
                    begin:false,
                    getAudio:null,
                    RecorderAudio: 0,  //  判断  当前 音频为录音  或  已录音音频    0 未录音   1 已录音在线音频   2本地录制音频
                    Media:null, // 录音播放 多媒体
                    MediaInPlay:false,  // 录音播放状态
                },
                // 录音动态效果
                wave:null,
                waveIndex:0,

                //是否作答
                hasRecording:false,
                HintAudioPlayerUrl:"",//提示音频地址
                HintAudioPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                recorderPlayerUrl:"",//录音音频地址
                recorderPlayerConfig:{
                    autoplay:false,
                    theSpeeds:[],
                },
                videoSrc:""
            }
        },
        components:{popup,Head},
        created() {
            if(this.menulist.length>0){
                this.Panduan = true;
                this.getinfo();
            }else{
                this.Panduan = false;
            }

        },
        mounted(){
            this.RecordeItem.Media = document.createElement('audio');

        },
        computed:{
            datacomplate:function(){
                return this.$store.state.common.datacomplate;
            },
            dataLayer:function(){
                return this.$store.state.common.dataLayer;
            },
            RecordBtn:function(){
                return this.$store.state.common.RecordBtn;
            },
            testPause:function(){
                return this.$store.state.common.testPause;
            },
        },
        watch:{
            datacomplate:function(v){
                if(!this.Panduan){
                    this.getinfo();
                }
            },
            dataLayer:function(){
                this.getinfo();
            },
            testPause:function(v,oldv){
                if(this.Pattern=='测试模式'){
                    if(v&&!oldv){ // 开始
                        if(!!this.QuestionChange){
                            this.QuestionChange();
                        }else{
                            if(!!this.pauseBegin)this.pauseBegin();
                        }
                    }
                    if(oldv&&!v){ // 暂停
                        if(!!this.stayPause)this.stayPause();
                    }
                }
            },
            threeActive:function(v){
                if(v=='KEY'&&!!this.CheckKey){
                    this.CheckKey();
                }
            },
        },
        methods:{
            videoEndedOver(){
                console.log('视频播放完成');
            },
            stayPause(){

            },
            Wheight(i){
                let t = i - 1;
                let c = Math.abs(Math.abs((Math.floor(t/5)*5 - t%5)) * 40 - 300) - 30;
                let d = 300*300 - c*c;
                d = Math.sqrt(d)*2;
                return d;
            },

            async getinfo(){
                this.RecordeItem.Media = document.createElement('audio');
                let data = this.GetTargetItem();
                this.UpLayerData = data.updata; // 上 层数据
                if (!!data.last) {
                    this.Target = data.data[data.last];
                } else {
                    this.Target = data.data;
                }
                this.Target.title = data.title;
                // 判断 学习模式
                this.$store.commit('setitem',{'name':'Pattern','item':this.Target.bindRole.ruleTypeName});
                this.$store.commit('setitem',{'name':'RoleIsJudgment','item': this.Target.bindRole.isJudgment});
                // 判断 绑题规则 设置 notes key txt
                this.BindRole = this.Target.bindRole;
                this.threeShow(this.BindRole);
                if (this.BindRole.isRecordNote == 1) this.mshow.notes = 1;
                else this.mshow.notes = 0;

                if (this.BindRole.isReadAnswer == 1) this.mshow.key = 1;
                else this.mshow.key = 0;

                if (this.BindRole.isReadOriginal == 1) this.mshow.text = 1;
                else this.mshow.text = 0;
                // 判断 绑题规则 设置 notes key txt
                if (this.Target.isEnd == 1 && data.data.length > 1) {
                    this.RightLeft = true;
                } else {
                    this.RightLeft = false;
                }

                let qIndex = sessionStorage.getItem('questionIndex') || 0; // 问题下标
                this.questionIndex = sessionStorage.getItem('SonquestionIndex') || 0; // 问题子题下标
                this.questionAll = this.Target.questionList.length; // 问题 总数
                console.log(this.Target.questionList[qIndex]);

                let questiondata = await this.GetQuestion(this.Target.questionList[qIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;
                this.childQuestionData = this.questionData.childQuestionList[this.childQuestionListIndex];
                // console.log(this.questionData);
                this.childQuestionListAll = this.questionData.childQuestionList.length;

                if(!!this.questionData.questionText&&this.questionData.questionText!='null'&&this.questionData.questionText!=''){
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg,'');
                    this.questionText = `<span>Subject text</span><div>${this.questionData.questionText}</div>`;
                }else{
                    this.questionText = '';
                }
                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if(notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }

                this.$store.commit('setitem',{'name':'RecordBtn','item':false})
                console.log(this.BindRole);
                // this.FlashPlay();
                this.videoSrc = this.questionData.textResource
                if(this.BindRole.isAutoPaly == 1){
                    this.$refs.VideoPlayer && this.$refs.VideoPlayer.startPlay()
                    this.InPlay = true;
                }
            },

            getVideoInfo(data){
                let item = {
                    autoplay:true,
                    controls:true,
                    loop:false,
                    preload:'auto',
                    sources:[
                        {
                            type:"rtmp/mp4",
                            src:data,
                        }
                    ],
                }
                this.videoPlayer = videoJs('Myvideo',item);
                this.videoPlayer.on('ended',()=>{
                    console.log('ended');

                    this.InPlay = false;
                    this.PlayerComplete();
                })
                this.videoPlayer.on('play',()=>{
                    this.InPlay = true;
                    console.log('主音频播放中');
                })
                this.videoPlayer.on('pause',()=>{
                    console.log('主音频暂停中');
                    this.InPlay = false;
                })

            },
            // 保存 笔记
            async SaveNotes(){
                let question = this.childQuestionData;
                let item = this.NoteItem;
                item.content = item.content.replace(/(^\s*)|(\s*$)/g,'');
                if(!!!item.content){
                    this.$message.warning('请填写笔记!');
                    return false;
                }

                let layer = sessionStorage.getItem('targetceng').split(',');
                layer = layer.map(el=>{
                    el = el.split('.');
                    return el;
                })
                let data = {
                    courseId:this.menulist[layer[0][1]].courceId,
                    nodeId:this.Target.id,
                    title:'',
                    content:item.content,
                    questionId:question.id,
                    levelName:this.Target.catalog,
                }

                if(!!item.id){
                    let edit_ = await this.AjaxApi.updateCourseNote(item);
                    if(edit_.code == '200'){
                        this.$message.success('修改笔记成功!');
                    }
                }else{
                    let add_ = await this.AjaxApi.addCourseAdvice(data);
                    if(add_.code == '200'){
                        this.$message.success('添加笔记成功!');
                        this.NoteItem.id = add_.data.id;
                    }
                }

            },// 录音  控制
            Record_a(it) {
                if (it == "1") {
                    if (this.Pattern == "测试模式") {
                        return;
                    }
                }

                this.$store.commit("setAdd", "MenuSwitch");
                // 暂停视频播放
                this.$refs.VideoPlayer.pausePlay()
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false

                // 检测是否有麦克风
                let checkInfo = JSON.parse( sessionStorage.checkInfo ) 
                if( !!!checkInfo.audioInput){
                    this.$message.warning("无音频输入设备!");
                    return
                }

                this.waveIndex = 0;
                let list = document.querySelectorAll("#Wave div");
                for (let index = 0; index < list.length; index++) {
                    let el = list[index];
                    el.className = "";
                }
                this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;
                if (!!this.BindRole.recordDownTime && this.BindRole.recordDownTime > 0) {
                    this.RecordeItem.time = this.BindRole.recordDownTime;
                } else {
                    this.RecordeItem.time = 35;
                }

                this.RecordeItem.timeshow = window.ComputedTime(this.RecordeItem.time);
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                    clearInterval(this.RecordeItem.Count);
                    let list = document.querySelectorAll("#Wave div");
                    for (let index = 0; index < list.length; index++) {
                        let el = list[index];
                        el.className = "";
                    }
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit("setitem", {
                        name: "RecordBtn",
                        item: false
                    });
                } else {
                    this.$store.commit("setitem", {
                        name: "RecordBtn",
                        item: true
                    });
                    this.wave = setInterval(() => {
                        let list = document.querySelectorAll("#Wave div");
                        if (list[this.waveIndex].className.indexOf("active") > -1) {
                            list[this.waveIndex].className = "";
                        } else {
                            list[this.waveIndex].className = "active";
                        }
                        if (this.waveIndex < 9) {
                            this.waveIndex++;
                        } else {
                            this.waveIndex = 0;
                        }
                    }, 35);

                    this.RecordeItem.Count = setInterval(() => {
                        if (this.RecordeItem.time > 0) {
                            this.RecordeItem.time--;
                            this.RecordeItem.timeshow = window.ComputedTime(
                                this.RecordeItem.time
                            );
                        } else {
                            this.recordTime = (parseInt(this.BindRole.recordDownTime)||35) - this.RecordeItem.time;
                            this.RecordeItem.RecorderAudio = 2;
                            Recorder.stop();
                            this.UpdateAudio();
                            this.$store.commit("setitem", {
                                name: "RecordBtn",
                                item: false
                            });
                            clearInterval(this.wave);
                            clearInterval(this.RecordeItem.Count);
                        }

                    }, 1000);

                    this.$refs.Recorder.handleBtnClick()

                    this.$store.commit("setitem", {
                        name: "RecordBtn",
                        item: true
                    });
                }
            },
            async UpdateAudio() {
                await this.$refs.Recorder.stopVoice()
            },
            async AddAudio(url_){
                this.recorderPlayerUrl = url_.data;
                let path = url_.data;
                // 新增录音记录
                let data = {
                    courseId:this.$store.state.common.curriculum,
                    filePath:path,
                    questionId:this.childQuestionData.id,
                    sectionId:this.Target.id,
                    recordTime:this.recordTime,
                    seatMealCourseId:sessionStorage.getItem('seatMealCourseId')||'abc',
                    uuid:sessionStorage.getItem('uuid'),
                }
                let add_ = await this.AjaxApi.addVideo(data);
                if(this.Pattern == '测试模式'){
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },

            MediaPlay(){
                if (this.RecordBtn) {
                    clearInterval(this.wave);
                        clearInterval(this.RecordeItem.Count);
                    this.RecordeItem.RecorderAudio = 2;
                    Recorder.stop();
                    this.UpdateAudio();
                    this.$store.commit('setitem', {
                        'name': 'RecordBtn',
                        'item': false
                    })
                }
                this.$refs.VideoPlayer.pausePlay() //暂停视频播放
                this.$refs.recorderPlayer.startPlayOrPause()
                // if(this.RecordeItem.RecorderAudio == 0){ // 未录音
                //     this.$message.warning('请先录制音频!');
                //     return false;
                // }
                // if(this.InPlay){
                //     this.InPlay = false;
                // }
                // if(this.RecordeItem.MediaInPlay){
                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.pause();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.stop();
                //     }
                // }else{
                //     let d = window.PlayerState(this.videoPlayer.paused());
                //     if(!d){
                //         this.videoPlayer.pause();
                //     }

                //     if(this.RecordeItem.RecorderAudio == 1){
                //         this.RecordeItem.Media.play();
                //     }
                //     if(this.RecordeItem.RecorderAudio == 2){
                //         Recorder.play({  // 播放 监听
                //             progress: function (milliseconds) {},
                //             finished:this.PlayChange,
                //         });
                //     }
                // }
                this.RecordeItem.MediaInPlay = !this.RecordeItem.MediaInPlay;
            },
            // 重新 播放
            ResetTime(){
                this.RecordeItem.Media.pause();
                this.RecordeItem.MediaInPlay = false;
                this.audioTime = 0;
                this.InPlay = true;
            },
            // 上一题
            async prevQuestion(){
                this.questionIndex--;
                this.QuestionChange();
            },
            // 下一题
            async nextQuestion(){
                this.questionIndex++;
                this.QuestionChange();
            },
            // 数据 改变
            async QuestionChange(){
                // 数据还原
                if(this.videoPlayer) {
                    this.videoPlayer.dispose() //销毁是删除video标签，播放下一次视频需要重新创建一个video标签
                    let Box = document.querySelector('.content_t')
                    let video = document.createElement('video')
                    video.id = 'Myvideo'
                    video.className = 'video-js vjs-default-skin vjs-big-play-centered flex-grid'
                    Box.appendChild(video);
                }
                this.childQuestionListIndex = 0;
                this.GetTime = false; // 获取 视频总时长
                this.audioTime = 0; // 滑块 value
                this.playertime = ''; //总时长
                this.InPlay = false; // 判断是否 播放中
                this.check = ''; // 判断是否选择 答案
                this.complate = false; // 是否提交答案
                this.answer = ''; // 答案
                this.ProgressBar = {
                    show:false,
                    width:100,
                    time:null,
                    duration:0,
                    timeing:0,
                }
                let questiondata = await this.GetQuestion(this.Target.questionList[this.questionIndex].oldId,this.Target.sectionMain.id);
                this.questionData = questiondata.data;  // 当前问题 数据
                this.childQuestionListAll = this.questionData.childQuestionList.length;// 当前问题 子题总数
                var questionTextAndAudio = JSON.parse(this.questionData.childQuestionList[this.childQuestionListIndex].title);
                if(!!this.questionData.questionText&&this.questionData.questionText!='null'&&this.questionData.questionText!=''){
                    this.questionData.questionText = this.questionData.questionText.replace(this.reg,'');
                    this.questionText = `<span>Subject text</span><div>${this.questionData.questionText}</div>`;
                }else{
                    this.questionText = '';
                }

                this.childQuestionData = this.questionData.childQuestionList[0];
                // 录音查询
                this.$refs.recorderPlayer.pausePlay()
                this.RecordeItem.MediaInPlay = false;
                let record_ = await this.AjaxApi.getNewVideo(this.childQuestionData.id);
                if(!!record_.data&&!!record_.data.filePath){
                    this.recorderPlayerUrl = record_.data.filePath
                    this.RecordeItem.RecorderAudio = '1';
                }else{
                    this.recorderPlayerUrl = null
                }
                let notes_ = await this.AjaxApi.getNoteByQuestionId(this.childQuestionData.id);
                if(!!notes_.data&&notes_.data.length>0){
                    this.NoteItem = notes_.data[0];
                }else{
                    this.NoteItem.content = '';
                    this.NoteItem.id = '';
                }
                // this.FlashPlay();
                this.InfoComplate = true;

                this.videoSrc = this.questionData.textResource
                if(this.BindRole.isAutoPaly == 1){
                    this.$refs.VideoPlayer && this.$refs.VideoPlayer.startPlay()
                    this.InPlay = true;
                }
            },
            // 提示音频播放 完成
            HintPlayerComplete() {
                if(this.BindRole.haveRecordDown == 1){
                    this.Record_a();
                }
            },
            // 题干音频播放 完成
            PlayerComplete() {
                console.log(this.BindRole);
                this.InPlay = false;
                // 判断 是否有 提示音
                if (this.BindRole.isNeedHintAudio == 1) { // 需要 提示音
                    this.HintAudioPlayerUrl = this.BindRole.hintAudio
                    this.HintAudioPlayerConfig.autoplay = true
                }else{
                    if(this.BindRole.haveRecordDown == 1){
                        this.Record_a();
                    }
                }
            },
            On_back(){
                this.T_back(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
            },
            On_next(){
                if(this.$store.state.common.MaxPattern=='闯关模式'){
                    if(!!this.hasRecording){
                        this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                    }else{
                        this.$message.warning('请先完成本题!');
                    }
                }else{
                    this.T_next(this.childQuestionListIndex,this.childQuestionListAll,this.questionIndex,this.questionAll);
                }
            },
        },
        destroyed() {
            // 删除 视频播放器
            if(!!this.videoPlayer){
                this.videoPlayer.dispose();
            }
            clearInterval(this.wave);
            clearInterval(this.RecordeItem.Count);
        },
    }
</script>

